<template>
    <button :disabled="this.disabled">{{ buttonText }}</button>
</template>

<script>
export default {
    name: 'Button',
    props: {
        buttonText: String,
        disabled: Boolean
    }
}
</script>

<style scoped>
button {
    cursor: pointer;
    background: var(--primary);
    padding: 12px 24px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    border: 2px solid var(--primary);
}

button:hover {
    background: white;
    color: var(--primary);
}

button:focus {
    background: var(--base-color-300, #DEDEDE);
    box-shadow: 4px 4px 0px 0px var(--primary);
}

button:disabled {
    background: var(--base-color-400, #C8C8C8);
    color: white;
    border: none;
    cursor: not-allowed;
}
</style>
