<template>
    <section>

        <div class="discover-container">
            <h2>Descubre tus oportunidades en Alcalá la Real</h2>
            <p>La localización idónea para tu negocio existe y estás a punto de descubrirla.</p>
        </div>

        <Button class="btn" @click="handleClick" buttonText="Ir a la herramienta" />

    </section>
</template>


<script>
import Button from './Button.vue'

export default {
    name: 'DiscoverCard',
    components: {
        Button
    },
    methods: {
        handleClick() {
            this.$router.push({ name: 'MapPage' })
        }
    }
}
</script>


<style scoped>
section {
    background-color: #fff9;
    background-blend-mode: overlay;
    background-image: url('../../public/image 1.png');
    background-position: center;
    background-size: cover;
}

.discover-container {
    margin: 0 16px;
}

h2 {
    padding-top: 64px;
    margin-bottom: 36px;
}

section p {
    margin-bottom: 28px;
}

.btn {
    margin-bottom: 64px;
}

@media (min-width: 834px) {

    section {
        margin: 0;
        padding: 0 48px;
        margin-bottom: 100px;
    }

    section p {
        text-align: center;
        margin-bottom: 28px;
    }


}
</style>