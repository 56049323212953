<template>
    <div class="container chart" v-if="this.areaData.markers?.length">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>¡No se pueden mostrar los datos!</h5>
            <h6>No existen datos de competidores por sector de esta zona.<strong> Por favor seleccione otra zona para
                    mostrar los datos.</strong></h6>
        </span>
    </div>
</template>


<script>
import { mapState } from 'vuex'
import getMarkersTags from '../../utils/getMarkersTags'
import getMarkersValues from '../../utils/getMarkersValues'

export default {
    name: 'ChartBars',
    computed: {
        ...mapState([ 'areaData' ])
    },
    data() {
        return {
            series: [ {
                data: this.areaData?.markers && getMarkersValues(this.areaData.markers),
                name: [ 'Comercios' ]
            } ],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: [ '#ad002e' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: true,
                    }
                },
                dataLabels: {
                    enabled: false
                },

                xaxis: {
                    categories: this.areaData?.markers && getMarkersTags(this.areaData.markers),
                },
            },
        }
    },
    mounted() {
        this.series[ 0 ].data = getMarkersValues(this.areaData.markers)
        this.chartOptions.xaxis.categories = getMarkersTags(this.areaData.markers)
    }
}
</script>


<style>
.container-chart {
    width: 562px;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>