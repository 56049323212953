<template>
    <div class="container chart" v-if="hasSaleValues">
        <apexchart type="bar" height="350" :options="chartOptions" :series="series"></apexchart>
    </div>

    <div class="alert-msg" v-else>
        <img src="/Exclamation Circle.svg" alt="alert">
        <span>
            <h5>¡No se pueden mostrar los datos!</h5>
            <h6>No existen suficientes personas censadas en la zona seleccionada. <strong> Por favor seleccione otra
                    zona para mostrar los datos.</strong></h6>
        </span>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import getSaleValues from '../../utils/getSaleValues'


export default {
    name: 'SaleDataChart',
    computed: {
        ...mapState([ 'areaData' ]),
        hasSaleValues() {
            return this.series.length && this.series[ 0 ].data.some(value => value !== 0);
        }
    },
    data() {
        return {
            series: [],
            chartOptions: {
                chart: {
                    type: 'bar',
                    height: 350
                },
                colors: [ '#ad002e' ],
                plotOptions: {
                    bar: {
                        borderRadius: 0,
                        horizontal: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: [ '< 100 m2', '100-200 m2', '200-300 m2', '300-400 m2', '400-500 m2', '>500 m2' ],
                },
                yaxis: {
                    title: {
                        text: 'Locales'
                    }
                },
                tooltip: {
                    y: {
                        formatter: (val) => {
                            return `${val} locales`
                        }
                    }
                }
            }
        }
    },
    watch: {
        'areaData.saleDistributions': {
            handler(newValue) {
                if (newValue) {
                    const saleValues = getSaleValues(newValue);
                    if (saleValues.some(val => val !== 0)) {
                        this.series = [ {
                            name: 'Locales',
                            data: saleValues
                        } ]
                    } else {
                        this.series = []
                    }
                } else {
                    this.series = []
                }
            },
            immediate: true
        }
    },
    mounted() {
        if (this.areaData.saleDistributions) {
            const saleValues = getSaleValues(this.areaData.saleDistributions);
            if (saleValues.some(val => val !== 0)) {
                this.series = [ {
                    name: 'Locales',
                    data: saleValues
                } ]
            }
        }
    }
}
</script>

<style scoped>
.container-chart {
    width: 562px;
}

.alert-msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 70%;
}

.alert-msg h5 {
    font-size: 16px;
    font-weight: 700;
    margin: 10px 5px;
}

.alert-msg h6 {
    font-size: 14px;
    line-height: 18px;
    margin: 10px 5px;
}
</style>
