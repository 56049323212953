<template>
    <div class="highlight-block">
        <div class="highlight-content">
            <h1>Elige Alcalá la Real, ciudad para invertir, ciudad para vivir</h1>

            <p>
                Gracias a nuestra herramienta de geolocalización puedes encontrar un punto en
                el que tus oportunidades de conversión sean mayores.
            </p>

            <Button class="btn" @click="handleClick" buttonText="Ir a la herramienta" />
        </div>
    </div>
</template>


<script>
import Button from './Button.vue';

export default {
    name: 'HighlightBlock',
    components: {
        Button
    },
    methods: {
        handleClick() {
            this.$router.push({ name: 'MapPage' })
        }
    }
}
</script>


<style scoped>
.highlight-block {
    padding: 80px 28px 0 28px;
    height: 595px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 65%, transparent 80%),
        url('../../public/Foto_Alcala_la_real.jpg');
    background-position: center;
    background-size: cover;
}

@media (min-width: 834px) {
    .highlight-block {
        padding: 0 40px;
        height: 472px;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 25%, transparent 80%), url('../../public/Foto_Alcala_la_real.jpg');
        background-position: center left;
        background-size: 120%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .highlight-block h1,
    .highlight-block p {
        margin-top: 0;
        text-align: start;
    }

    .highlight-content {
        width: 50%;
        margin: 100px 0;
    }

    .btn {
        display: flex;
        align-items: flex-start;
    }
}
</style>