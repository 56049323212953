<template>
    <div class="communication-cards-container">

        <h2>Y ahora... ¿Qué hacemos?</h2>

        <p>Si necesitas más información puedes ver aquí abajo diferentes vías de comunicación para ponerte en contacto
            con
            la persona indicada para resolver tus dudas:</p>

        <div class="cards-container">

            <CommunicationCardContent detail="Ayuntamiento" title="Conoce nuestras empresas"
                text="Si quieres más información sobre el tejido empresarial de Alaclá la Real, visita nuestra web."
                img="Foto_Alcala_la_real.jpg" url="https://industria.alcalalareal.es/" button="Ver" />

            <CommunicationCardContent detail="Ayuntamiento de Alcalá la Real" title="Asesoramiento al emprendedor"
                text="Si eres un emprendedor y quieres saber más sobre este tipo de apoyo..." img="support.png"
                @show-form="handleShowForm" button="Solicitar" />

        </div>

    </div>
</template>


<script>
import { mapState } from 'vuex'
import CommunicationCardContent from './CommunicationCardContent.vue'

export default {
    name: 'CommunicationCards',
    components: {
        CommunicationCardContent
    },
    computed: {
        ...mapState([ 'screenWidth' ])
    },
    methods: {
        handleShowForm(event) {
            this.$emit('show-form', event);
        }
    },
    emits: [ 'show-form' ]
}
</script>


<style scoped>
.communication-cards-container {
    padding: 100px 26px;
}

h2 {
    margin: 0 0 16px 0;
}

p {
    margin: 0;
    margin-bottom: 68px;
}

.cards-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media (min-width: 834px) {
    .communication-cards-container {
        padding: 100px 40px;
    }

    .cards-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }
}


@media (min-width: 1280px) {
    .communication-cards-container {
        padding: 100px 68px;
    }
}
</style>