import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import VueApexCharts from 'vue3-apexcharts'
import { VueReCaptcha } from 'vue3-recaptcha2'
import VueGtag from 'vue-gtag'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueApexCharts)
app.use(VueReCaptcha, { siteKey: '6LeJ-YQqAAAAABuY82HV4BtFf8jYRnbuteoCiXhF' })
app.use(VueGtag, {
    config: { id: "G-LPV4VSC2JV" }
})

app.mount('#app')
