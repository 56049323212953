import { createStore } from 'vuex'


export default createStore({
    state: {
        screenWidth: window.innerWidth,
        selectedLngLat: null,
        address: null,
        formType: null,
        areaData: {
            population: null,
            rent: null,
            malePopulationByAge: null,
            femalePopulationByAge: null,
            markers: null,
            rentalDistributions: [],
            saleDistributions: []
        },
        failedReport: false
    },
    mutations: {
        SET_SCREEN_WIDTH(state, newValue) {
            state.screenWidth = newValue
        },
        SET_SELECTED_LNG_LAT(state, newValue) {
            state.selectedLngLat = newValue
        },
        SET_ADDRESS(state, newValue) {
            state.address = newValue
        },
        SET_FORM_TYPE(state, newValue) {
            state.formType = newValue
        },
        SET_AREA_POPULATION(state, newValue) {
            state.areaData.population = newValue
        },
        SET_AREA_RENT(state, newValue) {
            state.areaData.rent = newValue
        },
        SET_MALE_POPULATION_BY_AGE(state, newValue) {
            state.areaData.malePopulationByAge = newValue
        },
        SET_FEMALE_POPULATION_BY_AGE(state, newValue) {
            state.areaData.femalePopulationByAge = newValue
        },
        SET_MARKERS(state, newValue) {
            state.areaData.markers = newValue
        },
        SET_RENTAL_DISTRIBUTION(state, newValue) {
            state.areaData.rentalDistributions = newValue
        },
        SET_SALE_DISTRIBUTION(state, newValue) {
            state.areaData.saleDistributions = newValue
        },
        SET_FAILED_REPORT(state, newValue) {
            state.failedReport = newValue
        }
    },
    actions: {
        setScreenWidth({ commit }, newValue) {
            commit('SET_SCREEN_WIDTH', newValue)
        },
        setSelectedLngLat({ commit }, newValue) {
            commit('SET_SELECTED_LNG_LAT', newValue)
        },
        setAddress({ commit }, newValue) {
            commit('SET_ADDRESS', newValue)
        },
        setFormType({ commit }, newValue) {
            commit('SET_FORM_TYPE', newValue)
        },
        setAreaPopulation({ commit }, newValue) {
            commit('SET_AREA_POPULATION', newValue)
        },
        setAreaRent({ commit }, newValue) {
            commit('SET_AREA_RENT', newValue)
        },
        setMalePopulationByAge({ commit }, newValue) {
            commit('SET_MALE_POPULATION_BY_AGE', newValue)
        },
        setFemalePopulationByAge({ commit }, newValue) {
            commit('SET_FEMALE_POPULATION_BY_AGE', newValue)
        },
        setMarkers({ commit }, newValue) {
            commit('SET_MARKERS', newValue)
        },
        setRentalDistribution({ commit }, newValue) {
            commit('SET_RENTAL_DISTRIBUTION', newValue)
        },
        setSaleDistribution({ commit }, newValue) {
            commit('SET_SALE_DISTRIBUTION', newValue)
        },
        setFailedReport({ commit }, newValue) {
            commit('SET_FAILED_REPORT', newValue)
        }
    }
})