<template>
    <div class="footer-container">

        <div class="footer-img">
            <img src="comercio-alcala-la-real.svg" alt="comercio alcala la real">
            <img src="NextGeneration.svg" alt="next generation ue">
            <img src="/gobierno-de-espana-web.svg" alt="spain gobernment">
            <img src="/Plan-recuperacion-transformacion-resiliencia-color.svg" alt="transformation plan ue">
            <img src="/ayuntamiento-alcala-la-real.svg" alt="ayto logo">
        </div>

        <div class="footer-content">
            <article>
                <p>Ayuntamiento de Alcalá la Real</p>
                <ul>
                    <li>953 580 000</li>
                    <li>Plaza del Arcipreste de Hita, 1</li>
                    <li><a href="mailto:alcaldia.presidencia@alcalalareal.es">alcaldia.presidencia@alcalalareal.es</a>
                    </li>
                </ul>
            </article>

            <article>
                <p>Áreas relacionadas</p>
                <ul>
                    <li><a href="https://industria.alcalalareal.es/">Industria</a></li>
                    <li><a href="https://comercio.alcalalareal.es/">Comercio</a></li>
                </ul>
            </article>

            <article>
                <p>Accesos directos</p>
                <ul>
                    <li><a href="https://alcalalareal.es/">Web municipal</a></li>
                    <li><a href="https://alcalalareal.sedelectronica.es/info.0">Sede electrónica</a></li>
                    <li><a href="https://transparencia.alcalalareal.es/">Portal de transparencia</a></li>
                    <li><a href="https://www.andaluciaemprende.es/CADE/cade-alcala-la-real/">CADE Alcalá la real</a>
                    </li>
                </ul>
            </article>
        </div>

        <hr class="footer-hr">

        <div class="footer-bottom">
            <h5>© 2024 Ayuntamiento de Alcalá la Real</h5>

            <div class="flex">
                <h5><a href="https://alcalalareal.es/politica-de-privacidad/">Política de privacidad</a>
                </h5>
                <h5><a href="https://alcalalareal.es/aviso-legal/">Aviso legal</a></h5>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.footer-container {
    width: calc(100% - 80px);
    margin: 0 auto;
    text-align: start;
    margin-top: 48px;
    display: flex;
    flex-direction: column;
}

.footer-img {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    margin-bottom: 0;
}

img {
    display: block;
    width: 70%;
    height: auto;
    max-height: 130px;
    margin: auto;
}

p {
    margin-top: 36px;
    margin-bottom: 20px;
    font-weight: 600;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

li {
    margin-bottom: 16px;
    font-size: 14px;
    width: fit-content;
}

li a:hover {
    color: var(--primary);
}

.footer-content a {
    text-decoration: none;
    font-size: 14px;
}

.footer-hr {
    border: 1px solid var(--base-900);
    margin: 28px 0;
    width: 100%;
}

h5 {
    margin: 0 0 12px 0;
    text-align: center;
    font-weight: 400;
}

a {
    text-decoration: none;
    color: black;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

@media (min-width: 834px) {
    .footer-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
    }

    .footer-img {
        flex-direction: row;
        gap: 5px;
        margin-bottom: 30px;
    }

    img {
        width: 20%;
        max-height: 100px;
    }

    .footer-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
    }

    article:first-child {
        width: 30%;
    }

    article:not(:first-child) {
        width: 26%;
    }

    .footer-bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
    }

    h5 {
        margin-left: 0;
        margin-right: 18px;
        margin-bottom: 0px;
        display: inline;
    }

    .flex {
        display: inline;
        justify-content: space-between;
        align-items: center;
    }

    .flex h5 {
        margin: 0 18px;
    }
}

@media (min-width: 1280px) {
    .footer-container {
        margin-left: 68px;
        margin-right: 48px;
        max-width: calc(100% - 116px);
        width: auto;
    }

    .footer-img {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 30px;
        align-items: center;
        margin-bottom: 48px;
    }

    img {
        width: 200px;
    }

    .footer-content {
        display: flex;
        width: 100%;
    }

    article:first-child {
        width: 30%;
    }

    article:not(:first-child) {
        width: 20%;
    }

    article {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    p {
        margin-top: 0;
        margin-bottom: 20px;
    }
}
</style>
